import React, {useState, useEffect} from 'react'
import {Link} from "gatsby";

const CookiesInfo = props => {
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('agree')) {
            setShowInfo(true)
        }
    }, [])

    const handleContinue = () => {
        localStorage.setItem('agree', 'true');
        setShowInfo(false)
    }

    return showInfo ? (
        <div className="fixed z-50 bottom-5 left-5 w-2/3 md:w-1/2 lg:w-1/3 bg-white rounded-xl shadow-2xl p-5 border-light-gray border">
            <p className="md:text-justify text-dark-gray text-sm">A <span className="font-bold">Mister Brinque</span> utiliza cookies e outras tecnologias semelhantes para melhorar a sua experiência,
                de acordo com a nossa <Link to="/politica-de-privacidade" className="text-purple underline">Política de Privacidade</Link> e, ao continuar navegando, você concorda com estas condições.
            </p>
            <div className="text-right">
                <button
                    onClick={handleContinue}
                    className="mt-2 px-5 py-2 text-sm shadow-sm font-medium tracking-wider transition-all border border-darkpurple text-darkpurple rounded-full hover:shadow-lg">CONTINUAR</button>
            </div>
        </div>
    ) : ''
}

export default CookiesInfo
