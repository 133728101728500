/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "../Header"
import Footer from "../Footer"
import Container from "../Container"
import CookiesInfo from "../CookiesInfo"

import "./style.scss"

const Layout = ({ children, home = null }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `MisterBrinque`}
        home={home}
      />
      <Helmet
        htmlAttributes={{
          class: home && "home",
        }}
      />
      <main>
        <Container>{children}</Container>
      </main>
      <Footer
          siteTitle={data.site.siteMetadata?.title || `MisterBrinque`}
      />
      <CookiesInfo/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
