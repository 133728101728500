/* eslint-disable global-require */
// leave off @2x/@3x
const images = {
    logo: require("../images/logo.svg").default,
    iconFacebook: require("../images/icon-facebook.svg").default,
    iconInstagram: require("../images/icon-instagram.svg").default,
    iconSeller: require("../images/icon-seller.svg").default,
    iconSellerWhite: require("../images/icon-seller_white.svg").default,
    iconLocation: require("../images/icon-location.svg").default,
    iconMail: require("../images/icon-mail.svg").default,
    iconTelephone: require("../images/icon-telephone.svg").default,
    iconChevronDoubleRight: require("../images/icon-chevron-double-right.svg").default,
    iconPdf: require("../images/icon-pdf.svg").default,
    iconSuccess: require("../images/icon-success.svg").default,
    iconMarker: require("../images/icon-marker.svg").default,
    iconLoading: require("../images/icon-loading.svg").default,
    folder: require("../images/folder.png").default,
    productHeight: require("../images/icon-product-height.png").default,
    productMeasures: require("../images/icon-product-measures.png").default,
    productFitsInBox: require("../images/icon-product-fitsinbox.png").default,
    productWeight: require("../images/icon-product-weight.png").default
}

export default images