import React, {useEffect, useState} from "react"
import {Link} from "gatsby"
import images from "../../../theme/images"
import "./style.scss"

const Header = props => {
    // determined if page has scrolled and if the view is on mobile
    const [scrolled, setScrolled] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)

    const {siteTitle} = props

    // change state on scroll
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled)
            }
        }

        document.addEventListener("scroll", handleScroll, {passive: true})

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener("scroll", handleScroll)
        }
    }, [scrolled])

    return (
        <header
            className={`z-50 w-full top-0 bg-gradient-to-b from-white to-light-gray border-solid border-b-4 border-primary ${
                scrolled ? "shadow-md fixed transition duration-300 animate-scrool" : "relative"
            } ${menuOpened ? "menuOpened" : ""}`}
        >
            <nav
                id="header"
                className="flex items-center justify-between flex-wrap lg:px-0"
            >
                <div className="container mx-auto flex">
                    <Link
                        to="/"
                        title={siteTitle}
                        className="flex items-center flex-shrink-0 text-white mr-6"
                    >
                        {/*<img
                          src={!scrolled ? images.logo : images.logoPrimary}
                          alt={siteTitle}
                        />*/}
                        <img loading="lazy" src={images.logo} alt={siteTitle} className={`${scrolled ? "w-40" : "w-52"}`}/>
                    </Link>
                    <div className="w-full flex justify-end lg:hidden">
                        {/* Show menu button */}
                        <button
                            type="button"
                            className="rounded hover:text-white hover:border-white m-4"
                            onClick={() => {
                                setMenuOpened(!menuOpened)
                            }}
                        >
                            <svg
                                className="fill-current h-6 w-6 text-darkpurple"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`w-full block lg:items-center lg:w-auto lg:flex flex-grow ${
                            menuOpened
                                ? "fixed lg:static top-0 right-0 bg-white lg:bg-transparent h-full z-100 px-4 py-5 lg:p-0 "
                                : "hidden"
                        }`}
                    >
                        {/* Close menu button */}
                        <button
                            type="button"
                            className={`rounded hover:text-white hover:border-white w-full lg:hidden ${
                                menuOpened ? "mb-12" : "hidden"
                            }`}
                            onClick={() => {
                                setMenuOpened(!menuOpened)
                            }}
                        >
                            <svg
                                viewBox="0 0 329 329"
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current  h-6 w-6 float-right text-darkpurple"
                            >
                                <path
                                    d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                            </svg>
                        </button>
                        <Link
                            to="/"
                            title="HOME"
                            className={`px-3 xl:px-6 lg:pr-5 text-shifting text-center block lg:inline-block hover:font-bold text-darkblue transition-colors duration-500 hover:bg-primary
                                ${menuOpened ? "text-primary" : ""}
                                ${scrolled ? "py-4 lg:py-6" : "py-4 lg:py-9"}
                            `}
                            activeClassName="bg-primary"
                        >
                            Home
                        </Link>
                        <Link
                            to="/sobre-nos"
                            title="SOBRE"
                            className={`px-3 xl:px-6 lg:pr-5 text-shifting text-center block lg:inline-block hover:font-bold text-darkblue transition-colors duration-500 hover:bg-primary
                                ${menuOpened ? "text-primary" : ""}
                                ${scrolled ? "py-4 lg:py-6" : "py-4 lg:py-9"}
                            `}
                            activeClassName="bg-primary"
                        >
                            <span className="md:hidden xl:inline">Nossa </span>História
                        </Link>
                        <Link
                            to="/produtos"
                            title="PRODUTOS"
                            className={`px-3 xl:px-6 lg:pr-5 text-shifting text-center block lg:inline-block hover:font-bold text-darkblue transition-colors duration-500 hover:bg-primary
                                ${menuOpened ? "text-primary" : ""}
                                ${scrolled ? "py-4 lg:py-6" : "py-4 lg:py-9"}
                            `}
                            activeClassName="bg-primary"
                            partiallyActive
                        >
                            Produtos
                        </Link>
                        <Link
                            to="/brincadeiras"
                            title="BRINCADEIRAS"
                            className={`px-3 xl:px-6 lg:pr-5 text-shifting text-center block lg:inline-block hover:font-bold text-darkblue transition-colors duration-500 hover:bg-primary
                                ${menuOpened ? "text-primary" : ""}
                                ${scrolled ? "py-4 lg:py-6" : "py-4 lg:py-9"}
                            `}
                            activeClassName="bg-primary"
                        >
                            Brincadeiras
                        </Link>
                        <Link
                            to="/contato"
                            title="CONTATO"
                            className={`px-3 xl:px-6 lg:pr-5 text-shifting text-center block lg:inline-block hover:font-bold text-darkblue transition-colors duration-500 hover:bg-primary
                                ${menuOpened ? "text-primary" : ""}
                                ${scrolled ? "py-4 lg:py-6" : "py-4 lg:py-9"}
                            `}
                            activeClassName="bg-primary"
                        >
                            <span className="md:hidden xl:inline">Canais de </span>Contato
                        </Link>
                        <a href="http://pedidos.misterbrinque.com.br/"
                           target="_BLANK"
                           className="text-center m-auto p-0.5 rounded-full transition hover:bg-primary duration-500"
                        >
                            <div className="table m-auto">
                                <img loading="lazy" src={images.iconSellerWhite} alt="Área Administrativa"
                                     className={`bg-primary rounded-full w-6 h-6 p-1 float-left`}/>
                                <span
                                    className={`lg:inline-block text-sm px-2 leading-none transition duration-500 text-darkblue text-xs font-semibold
                                  ${menuOpened ? "text-primary" : "text-white"}
                                }`}>
                                    Área Administrativa
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
