import React from "react"
import images from "../../../theme/images";
import "./style.scss"
import {Link} from "gatsby";

const Footer = props => {
    const {siteTitle} = props
    return (
        <footer className="bg-blue relative overflow-hidden">
            <div className="p-4 pb-8 flex flex-col lg:flex-row container mx-auto relative">
                <div className="mt-8 mr-8">
                    <img loading="lazy" src={images.logo} alt={siteTitle} className="w-60"/>
                    <div className="flex justify-center">
                        <a href="https://www.facebook.com/misterbrinque" target="_blank" className="hover:animate-bounce" rel="noreferrer">
                            <img loading="lazy" src={images.iconFacebook} alt="Curta nossa página no Facebook" className="w-10 m-3"/>
                        </a>
                        <a href="https://www.instagram.com/misterbrinque" target="_blank" className="hover:animate-bounce" rel="noreferrer">
                            <img loading="lazy" src={images.iconInstagram} alt="Siga-nos no Instagram" className="w-10 m-3"/>
                        </a>
                    </div>
                </div>
                <div className="pr-10">
                    <ul className="text-darkpurple">
                        <li>
                            <div className="flex content-start">
                                <img loading="lazy" src={images.iconLocation} alt="Curta nossa página no Facebook"
                                     className="w-5 m-3"/>
                                <div className="pt-5">
                                    <h2 className="text-xl font-semibold">Localização</h2>
                                    <p>Rua Armando Dias, 44<br/>Centro - Porto Feliz/SP<br/>CEP 18540-159</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="flex content-start">
                                <img loading="lazy" src={images.iconTelephone} alt="Curta nossa página no Facebook"
                                     className="w-8 m-3"/>
                                <div className="pt-5">
                                    <h2 className="text-xl font-semibold">Telefones</h2>
                                    <p>(15) 3261-2006<br/>(15) 3262-5799</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="flex content-start">
                                <img loading="lazy" src={images.iconMail} alt="Curta nossa página no Facebook"
                                     className="w-7 m-3 pl-1"/>
                                <div className="pt-5">
                                    <h2 className="text-xl font-semibold">E-mail</h2>
                                    <p>contato@misterbrinque.com.br</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="bg-1 hidden sm:block absolute transform rotate-45 right-0"/>
                <div className="bg-2 hidden sm:block absolute bg-darkpurple transform rotate-45 right-48 bottom-0"/>
            </div>
            <div className="p-4 mt-4 lg:mt-0 text-white bg-darkpurple relative text-center block">
                Direitos reservados à&nbsp;<strong>Mister Brinque</strong>&nbsp;&nbsp;•&nbsp;&nbsp;<Link to="/politica-de-privacidade">Política de Privacidade</Link>
            </div>
        </footer>
    )
}

export default Footer
